import React, { useEffect, useState, useMemo } from 'react'
import { AppBar, AuthWall, BottomNavView } from '@features'
import { Outlet, useLocation } from 'react-router-dom'
import { useAccountControllerV1GetUserProfiles } from '~/api/client'
import { Box, useTheme, Text, Flex } from '@chakra-ui/react'
import { datadogLogs } from '@datadog/browser-logs'
import { observer } from 'mobx-react-lite'

import { Loading } from '@components'
import { useWebViewStore } from '@hooks'
import { PatientProfileStoreProvider, useAppBarStore, usePatientProfileStore } from '@core/store'

import { theme } from '@blueprinthq/joy'
import { AppPrompt } from '@features'
import { useFlagsmith } from 'flagsmith/react'

const AuthedUserLayoutContent = observer(() => {
  const flagsmith = useFlagsmith()
  const { data: profile, isError } = useAccountControllerV1GetUserProfiles({
    query: {
      select: (profiles) => {
        // Defaulting to first profile for now until EoC work is supported for multiple profiles
        return profiles[0]
      }
    }
  })
  const appBarStore = useAppBarStore()
  const profileStore = usePatientProfileStore()

  useEffect(() => {
    if (profile) {
      profileStore.setActiveProfile(profile)
      appBarStore.setLogoUrl(profile.clinicLogoUrl)

      flagsmith.identify(profile.userId, {
        clinicId: profile.clinicId,
        patientId: profile.patientId
      })
      datadogLogs.setUser({ id: profile.userId, type: profile.userType })
      datadogLogs.logger.info('Monitoring authed user.')
    }

  }, [profile?.userId])

  const isLoading: boolean = useMemo(() => Boolean(!profileStore.activeProfile || (profile?.clinicLogoUrl && !appBarStore.logoUrl)), [profileStore.activeProfile, profile?.clinicLogoUrl, appBarStore.logoUrl])

  const showNoClinicianBanner: boolean = false
  // useMemo(() => Boolean(profile?.isDischarged || profile?.hasNoClinician), [profile])
  // will being this back when the logic is straightened out

  return (
    <>
      <AppBar isLoading={isLoading} />
      <Box
        height={{
          // @ts-ignore
          base: `calc(100vh - ${theme.components.AppBar.baseStyle.height.base})`,
          // @ts-ignore
          sm: `calc(100vh - ${theme.components.AppBar.baseStyle.height.sm})`,
          // @ts-ignore
          xl: `calc(100vh - ${theme.components.AppBar.baseStyle.height.xl})`
        }}
      >
        {showNoClinicianBanner && (
          <Flex w="100%" p="small" bg="#F6B5B5" justify="center">
            <Text>
              Your clinician is no longer able to view your assessment or check-in submissions. Please reach out to them directly for more information.
            </Text>
          </Flex>
        )}
        {isError ? (
          <Box p="small">
              An error occured. Please try closing and reopening the app.
          </Box>
        ) : isLoading ? (
          <Loading isFullPage />
        ) : (
          <Outlet />
        )}
      </Box>
    </>
  )
})

const primaryNavRoutes = ['/', '/progress', '/settings']
const fullBackgroundRoutes = primaryNavRoutes.concat(['/settings/notifications'])

export const AuthedUserLayout = () => {
  const [isNavMenuVisible, setIsNavMenuVisible] = useState<boolean>(false)

  const theme = useTheme()
  const webViewStore = useWebViewStore()
  const location = useLocation()
  const appBarStore = useAppBarStore()

  const isPrimaryNavRoute = primaryNavRoutes.includes(location.pathname)
  const isFullBackgroundRoute = fullBackgroundRoutes.includes(location.pathname)

  useEffect(() => {
    if ((webViewStore.isReactNativeWebView ? !webViewStore.isUsingOldNav() : true) && isPrimaryNavRoute) {
      setIsNavMenuVisible(true)
    } else {
      setIsNavMenuVisible(false)
    }
  }, [isPrimaryNavRoute])

  useEffect(() => {
    if (isFullBackgroundRoute) {
      document.body.style.backgroundColor = theme.colors.pale_blue
    } else {
      document.body.style.backgroundColor = theme.colors.white
    }

    return () => {
      document.body.style.backgroundColor = theme.colors.white
    }
  }, [isFullBackgroundRoute])

  useEffect(() => {
    if (!isPrimaryNavRoute) {
      appBarStore.toggleCheckInVisibility(false)
    } else {
      appBarStore.toggleCheckInVisibility(true)
    }

    return () => {
      appBarStore.toggleCheckInVisibility(false)
    }
  }, [isPrimaryNavRoute])

  return (
    <AuthWall unauthedRedirect="/login">
      <PatientProfileStoreProvider>
        <AuthedUserLayoutContent />
        {isNavMenuVisible && <BottomNavView /> }
        {(isNavMenuVisible && !webViewStore.isReactNativeWebView) &&  <AppPrompt /> }
      </PatientProfileStoreProvider>
    </AuthWall>
  )
}
